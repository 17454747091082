import { Controller } from "stimulus"
import { Loader } from 'google-maps';
import { mapToStyles } from "@popperjs/core/lib/modifiers/computeStyles";

export default class extends Controller {
  static targets = [ "map" ]

  async connect() {
    const google = await this.createGoogleClient()
    const map = this.loadMap(google)
    
    var markers = this.mapTarget.dataset.markers
    var marker = this.mapTarget.dataset.marker

    if (marker != null) {
      this.setMarker(google, map)
    } else if (markers != null) {
      this.setLocationDot(google, map)
      this.setMarkers(google, map, markers)
    }
    if (marker == null) {
      this.initAutocomplete(google,map)
    }
  }

  createGoogleClient() {
    const loader = new Loader('AIzaSyAA4mVrTNzuUa5Zc8z04OdZ5qRkWQIa2Yo', {
      libraries: ['places'],
    });
    return loader.load();
  }
  
  loadMap(google) {
    var baseCenter = { lat: 48.8534, lng: 2.3488 }
    return new google.maps.Map(this.mapTarget, {
        center: baseCenter,
        zoom: 6,
    });
  }
  
  setMarker(google, map) {
    const marker = JSON.parse(this.mapTarget.dataset.marker)
    var bounds = new google.maps.LatLngBounds(marker);
    map.setCenter(marker);
    map.setZoom(18);
    const mapMarker = new google.maps.Marker({
      position: marker,
      map: map
    })
    // const service = new google.maps.places.PlacesService(map);
    // service.nearbySearch({ location: marker, type: 'pharmacy', radius: 50 }, (results, status) => {
    //   if (status !== "OK" || !results) return;
    //   if (results[0].photos) {
    //     const imgDiv = document.querySelector('.img-right')
    //     const photoUrl = results[0].photos[0].getUrl({maxWidth: 1000, maxHeight: 1000})
    //     imgDiv.style.backgroundImage = `url(${photoUrl})` 
    //     imgDiv.style.backgroundSize = 'cover'
    //   }
    // })

  }

  initAutocomplete(google,map) {
    var baseCenter = { lat: 48.8534, lng: 2.3488 }
    const defaultBounds = {
      north: baseCenter.lat + 0.1,
      south: baseCenter.lat - 0.1,
      east: baseCenter.lng + 0.1,
      west: baseCenter.lng - 0.1,
    };
    const input = document.getElementById("search-form");
    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: "fr" },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
    };
    return new google.maps.places.Autocomplete(input, options) 
  }

  setLocationDot(google, map) {
    if (this.mapTarget.dataset.dot) {
      const marker = new google.maps.Marker({
        map: map,
        position: JSON.parse(this.mapTarget.dataset.dot),
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: '#00F',
          fillOpacity: 0.6,
          strokeColor: '#00A',
          strokeOpacity: 0.9,
          strokeWeight: 1,
          scale: 7
        }
      });

      const radius = this.mapTarget.dataset.radius
      if (radius) {
        const circle = new google.maps.Circle({
          strokeColor: "#16C995",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FFFFFF",
          fillOpacity: 0.1,
          map,
          radius: JSON.parse(radius) * 1000,
        });
        circle.bindTo('center', marker, 'position')
      }
    }
    // map.setZoom(18)
  }

  setMarkers(google, map, markers) {
    markers = JSON.parse(markers)
    const mapMarkersArray = []
    if (markers) {
      markers.forEach(marker => {
        // create infowindow
        var markerInfowindow = new google.maps.InfoWindow({
          content: marker.infowindow,
        });
        // create google object Marker
        var mapMarker = new google.maps.Marker({
          position: {lat: parseFloat(marker.lat), lng: parseFloat(marker.lng)},
          map: map,
          infowindow: markerInfowindow,
          id: marker.id,
        })
        mapMarkersArray.push(mapMarker)
        // show infowindow on click on marker
        mapMarker.addListener('click', function(){
          mapMarkersArray.forEach(function(marker) {
            marker.infowindow.close(map, marker);
          }); 
          mapMarker.infowindow.open({
            anchor: mapMarker,
            map,
          });
        });
      });

      // open marker infowindow when hovering the corresponding card
      const cards = document.querySelectorAll(".index-card")
      cards.forEach(card => {
        var markerHover = mapMarkersArray.find(marker => marker.id === parseInt(card.dataset.id))
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
          // console.log("sur mobile")
        }else{
          card.addEventListener("mouseenter", () => {
            markerHover.infowindow.open({anchor: markerHover, map})
          });
          // close infowindow
          card.addEventListener("mouseleave", () => {
            markerHover.infowindow.close({anchor: markerHover, map})
          })
        }
        var marker = markers.find(marker => marker.id === parseInt(card.dataset.id))
      });
    }
    if (markers.length > 0) {
    this.fitMap(google, map, markers)
    }
  }
  
  // center and fit map to the markers
  fitMap(google, map, markers) {
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < markers.length; i++) {
      bounds.extend(markers[i]);
    }
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());
  }
}
